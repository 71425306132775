@import "Assets/Styles/colours.scss";

.footer-logo {
    order: 0;
    flex: 0 1 auto;
    align-self: auto;
    background-color: $primary;

    .image {
        max-width: 40%;
        display: block;
        margin: 0 auto
    }
}
