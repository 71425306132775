@import "Assets/Styles/colours.scss";

.search-box {
    order: 0;
    flex: 0 1 auto;
    align-self: auto;
    width: 100%;

    form {
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
        margin: -105px 20px 0 20px;
        background-color: $white;
        border-radius: 0.25rem;
        box-shadow: 0 3px 15px rgba(0, 0, 0, 0.16);
        width: 89.5%;

        .search-error {
            color: #dc3232;
            padding: 20px 0 0 0;
            text-align: center;
            font-size: 13px;
            text-transform: uppercase;
            font-family: Arial;
            margin: 0 15px;
        }

        .container {
            color: #343b45;
            width: 100%;
            height: auto;
            border-radius: 0.25rem;

            .first {
                margin-top: 3% !important;
            }

            .last {
                margin-bottom: 4% !important;
            }

            .input-container {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin: 1.5% 8%;
                border-bottom: 2px solid $secondary;
                transition: 0.4s ease;
                width: 84%;

                &:hover {
                    border-bottom: 2px solid $primary;
                }

                svg {
                    fill: $primary;
                    height: 26px;
                    padding-bottom: 5px;
                    width: 32px;
                    margin-right: 10px;
                }

                input {
                    padding: 7px 0px;
                    font-size: 16px;
                    box-sizing: border-box;
                    border: none;
                    width: 84%;
        
                    &:focus {
                        outline: 0 none;
                    }
                }
            }
        
            button {
                padding: 7px 0;
                background-color: $primary;
                border: 0;
                cursor: pointer;
                color: $white;
                width: 100%;
                border-radius: 0rem 0rem 0.25rem 0.25rem;
                display: inline-flex;
                align-items: center;

                span {
                    display: block;
                    margin: 0 auto;
                    
                    img {
                        height: 20px;
                        width: 21px;
                        cursor: pointer;
                    }
                }
        
                &:focus {
                    outline: none;
                }

                &:hover {
                    background-color: $secondary;
                    -webkit-transition: background-color 0.5s ease-out;
                    -moz-transition: background-color 0.5s ease-out;
                    -o-transition: background-color 0.5s ease-out;
                    transition: background-color 0.5s ease-out;
                }
            }
        } 
    }
}