@import "Assets/Styles/colours.scss";


.show {
    display: block;
};
.hide {
    display: none;
}
.modal-container {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    background-color: rgba(0,0,0,0.65);
    z-index: 999;

    .modal {
        position: fixed;
        top: 50px;
        left: 0;
        margin: 21px;
        background-color: transparent;
        width: 354px;
        border-radius: 0.25rem;

        .header {
            color: $white;
            text-align: left;
            padding: 10px 0 10px 10px;
            background-color: $secondary;
            border-radius: 0.25rem 0.25rem 0 0;
            display: flex;
            align-items: center;
            align-content: center;
            font-size: 21px;
    
            .icon {
                border-radius: 50%;
                background-color: $white;
                height: 28px;
                width: 28px;
                margin-right: 15px;
    
                svg {
                    height: 22px;
                    width: 22px;
                    padding: 3px;
    
                    .order-b {
                        fill: $secondary;
                    }
                }
            }
        }

        .body {
            height: auto;
            padding: 15px;
            background-color: $white;
            border-radius: 0 0 0.25rem 0.25rem;

            .error {
                background-color: $white;
                word-break: break-word;
                color: #dc3232;
                padding: 10px 0 10px;
                text-align: center;
                font-size: 13px;
                text-transform: uppercase;
                font-family: Arial;
                width: 100%;
                margin: 0;
            }

            .success {
                background-color: $white;
                word-break: break-word;
                color: #29ab87;
                padding: 10px 0 10px;
                text-align: center;
                font-size: 13px;
                text-transform: uppercase;
                font-family: Arial;
                margin: 0;
            }

            .input-container-textarea {
                width: 100%;
                border-bottom: 2px solid $secondary;
                padding: 5px 0;
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                align-content: flex-start;

                svg {
                    height: 20px;
                    width: 20px;
                    padding-top: 3px;
                }

                textarea {
                    border: none;
                    outline: none;
                    width: 100%;
                    font-size: 16px;
                    text-indent: 5px;
                    font-family: Source Sans Pro;

                    &::placeholder {
                        color: #c1c1c1;
                        opacity: 1;
                        font-family: Source Sans Pro;
                    }
                }
            }

            .select-container {
                width: 100%;
                border-bottom: 2px solid $secondary;
                padding: 8px 0;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                align-content: center;

                .custom-select {
                    border: 1px solid #ccc;
                    width: 99%;
                    border-radius: 3px;
                    overflow: hidden;

                    span {
                        font-family: Source Sans Pro;
                        color: #8b8b8b;
                    }

                    select {
                        padding: 5px 8px;
                        width: 100%;
                        cursor: pointer;
                        border: none;
                        box-shadow: none;
                        background: transparent;
                        background-image: none;
                        -webkit-appearance: none;

                        &:focus {
                            outline: none;
                        }
                    }
                }
            }

            .input-container {
                width: 100%;
                border-bottom: 2px solid $secondary;
                padding: 8px 0;
                display: flex;
                flex-direction: row;
                align-items: center;
                align-content: center;

                svg {
                    height: 20px;
                    width: 20px;
                }

                input {
                    border: none;
                    outline: none;
                    width: 100%;
                    font-size: 16px;
                    text-indent: 5px;
                    font-family: Source Sans Pro;

                    &::placeholder {
                        color: #c1c1c1;
                        opacity: 1;
                        font-family: Source Sans Pro;
                    }
                }
            }

            .button-container {
                margin: 20px -15px -15px -15px;

                button {
                    width: 50%;
                    border: none;
                    outline: none;
                    transition: 0.2s ease;
                    color: $white;
                    padding: 8px 0;
                    cursor: pointer;

                    &:hover {
                        outline: none;
                    }

                    &:focus {
                        outline: none;
                    }

                    &:first-of-type {
                        border-bottom-left-radius: 0.25rem;
                        background-color: rgba(122, 179, 218, 1);

                        &:hover {
                            background-color: $secondary;
                        }
                    }

                    &:last-of-type {
                        border-bottom-right-radius: 0.25rem;
                        background-color: $primary;

                        &:hover {
                            background-color: $secondary;
                        }
                    }
                }
            }
        }
    }
}