.logo-container {
    order: 0;
	flex: 0 1 auto;
    align-self: auto;
    height: 31%;
    width: 100%;
    
    .logo {
        width: 100%;
        height: 190px;
        background-image: url('../../Assets/Logos/Hybris.png');
        background-size: 48% 28%;
        background-repeat: no-repeat;
        background-position: center;
        background-position-y: 8px;
        background-color: rgba(0, 119, 200, 1);
        box-shadow: 0 3px 15px rgba(0, 0, 0, 0.16);
    }
}