@import "Assets/Styles/colours.scss";

.results-card {
    border-top: 1px solid rgba(195, 193, 195, 1);
    background-color: $white;

    &:first-of-type {
        margin:0;
    }

    .card-header {
        color: $primary;
        font-weight: bold;
        text-align: left;
        padding: 10px 0 5px 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        align-content: center;

        .card-header-icon {
            border-radius: 50%;
            background-color: $primary;
            height: 28px;
            width: 28px;
            margin-right: 15px;

            svg {
                height: 22px;
                width: 22px;
                padding: 3px;

                .ba {
                    fill: $white;
                }
            }
        }

        .order-container {
            width: 100%;

            div {
                background-color: $secondary;
                border-radius: 50%;
                width: 28px;
                height: 28px;
                color: $white;
                font-weight: bolder;
                text-align: center;
                font-size: 20px;
                box-shadow: 0 -1px 10px rgba(0, 0, 0, 0.16);
                cursor: pointer;
                margin-right: 15px;
                float: right;

                &:hover {
                    background-color: $primary;
                    -webkit-transition: background-color 0.5s ease-out;
                    -moz-transition: background-color 0.5s ease-out;
                    -o-transition: background-color 0.5s ease-out;
                    transition: background-color 0.5s ease-out;
                }
            }
        }
    }

    .content-container {
        padding: 0 10px 5px 55px;
        color: #000;
        display: block;

        .content {
            position: relative;
            display: flex;
            flex-direction: row;
            flex-flow: row;
            align-items: center;

            &::before {
                content: "\2022";
                color: rgba(195, 193, 195, 1);
                font-weight: 900;
                font-size: 22px;
                display: inline-block;
                margin: 0em 0.4em 0 0;
            }

            div {
                font-size: 13px;
                word-break: break-word;
                background-color: $white;
                padding: 5px 5px;
                width: 100%;
                border-radius: 0.25rem;
            }

            span {
                font-size: 13px;
                white-space: nowrap;
                color: $primary;
                font-weight: 900;
            }
        }
    }
}