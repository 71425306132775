@import "Assets/Styles/colours.scss";

.section-selector {
    order: 0;
    flex: 0 1 auto;
    align-self: auto;
    width: 100%;
    display: inline-flex;
    flex-direction: row;

    button {
        padding: 5px 0;
        margin: 0;
        border-radius: 0;
        word-spacing: 200px;
        border: none;
        color: $white;
        background-color: $primary;
        cursor: pointer;

        &:focus {
            outline: none;
        }

        svg {
            height: 24px;
            width: 24px;
        }
    }

    .active {
        background-color: rgba(0, 119, 200, 0.48);
    }
}