@import "Assets/Styles/colours.scss";

.error {
    background-color: #dc3232;
    color: $white;
    padding: 10px;
    text-align: center;
    font-size: 13px;
    border-radius: 0.25rem;
    margin: 20px;
    text-transform: uppercase;
    font-family: Arial;
}