@import "Assets/Styles/colours.scss";

.pagination {
    order: 0;
    flex: 0 1 auto;
    align-self: auto;
    color: #000;
    display: block;
    margin: 15px 15px 0 15px;
    padding: 7px 7px 0 7px;
    text-align: center;

    span {
        color: $secondary;
        font-size: 14px;
        padding: 0 8px;
        cursor: pointer;
    }

    .page {
        background-color: $white;
        border-radius: 50%;
        min-width: 27.79px;
        max-width: 27.79px;
        margin: 0;
        color: $secondary;
        border: none;
    }

    .active {
        background-color: $secondary;
        color: $white;
        border-radius: 50%;
        margin: 0;
        width: auto;
        cursor: default;
        height: 20px;
        width: auto;
        padding: 2.5px 0;
        text-align: center;
        display: inline-block;
    }

    .indicator {
        font-size: 13px;
        margin: 14px 0;
        color: rgba(195, 193, 195, 1);
    }
}