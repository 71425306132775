@import "Assets/Styles/colours.scss";

.init-container {
    margin: 20px;
    padding: 15px 15px;
    border-radius: 0.25rem;
    background-color: $white;
    font-family: Arial;
    color: #4A4A4A;
    font-size: 16px;
    word-break: break-word;
    box-shadow: 0 3px 15px rgba(0, 0, 0, 0.16);

    div {
        text-transform: uppercase;
        font-weight: bolder;
        font-size: 20px;
        margin-bottom: 10px;
        color: #4D4D4D;
    }

    span {
        color: $secondary;
        font-weight: bold;
    }

    a {
        text-decoration: none;
    }

    .geeks-logo img {
		max-width: 25%;
		margin-top: 15px;
		margin-left: 0px;
	}
}
