@import "Assets/Styles/colours.scss";

.highlight-card {
    order: 0;
    flex: 0 1 auto;
    align-self: auto;
    width: 89.5%;
    border-radius: 0.25rem;
    box-shadow: 0 3px 15px rgba(0, 0, 0, 0.16);
    height: auto;
    margin: -60px 20px 20px 20px;

    .clear {
        color: $white;
        cursor: pointer;
        margin-left: 10px;
        margin-top: -80px;
    
        &:hover {
            text-decoration: underline;
        }
    }

    .header {
        color: $white;
        text-align: left;
        padding: 10px 0 10px 20px;
        background-color: $secondary;
        border-radius: 0.25rem 0.25rem 0 0;
        display: flex;
        align-items: center;
        align-content: center;
        font-size: 21px;

        button {
            border-radius: 30px;
            background-color: $white;
            color: $secondary;
            border: none;
            outline: none;
            transition: 0.2s ease;
            display: flex;
            flex-direction: row;
            align-items: center;
            align-content: flex-start;
            align-self: flex-start;
            padding: 4px 12px;
            margin: 5px;
            cursor: pointer;
            margin-left: 60px;

            &:hover {
                outline: none;
                background-color: $primary;
                color: $white;
            }

            &:focus {
                outline: none;
            }
        }

        .icon {
            border-radius: 50%;
            background-color: $white;
            height: 28px;
            width: 28px;
            margin-right: 15px;

            svg {
                height: 22px;
                width: 22px;
                padding: 3px;

                .order-b {
                    fill: $secondary;
                }
            }
        }
    }

    .container {
        padding: 0 10px 5px 15px;
        color: #000;
        background-color: $white;
        border-radius: 0 0 0.25rem 0.25rem;
        height: auto;

        button {
            border-radius: 30px;
            background-color: $secondary;
            color: $white;
            border: none;
            outline: none;
            transition: 0.2s ease;
            display: flex;
            flex-direction: row;
            align-items: center;
            align-content: flex-start;
            align-self: flex-start;
            padding: 4px 12px;
            margin: 5px;
            cursor: pointer;

            &:hover {
                outline: none;
                background-color: $primary;
            }

            &:focus {
                outline: none;
            }
        }

        .content {
            position: relative;
            display: flex;
            flex-direction: row;
            flex-flow: row;
            align-items: center;
            width: 100%;

            &::before {
                content: "\2022";
                color: rgba(195, 193, 195, 1);
                font-weight: 900;
                font-size: 17px;
                display: inline-block;
                margin: 0em 0.4em 0 0;
            }

            div {
                font-size: 13px;
                word-break: break-word;
                background-color: $white;
                padding: 0 5px;
                width: 100%;
                border-radius: 0.25rem;
                display: flex;
                flex-direction: row;
                align-items: center;

                button {
                    border: none;
                    outline: none;
                    background-color: $secondary;
                    color: $white;
                    border-radius: 30px;
                    padding: 4px 12px;
                    margin-left: 8px;
                    transition: 0.2s ease;
                    cursor: pointer;

                    &:hover {
                        outline: none;
                        background-color: $primary;
                    }

                    &:focus {
                        outline: none;
                    }
                }
            }

            span {
                white-space: nowrap;
                color: $primary;
                font-weight: 700;
                font-size: 14px;
            }
        }
    }
}